.state-col{
	padding-right:0;
	margin-right:0;
}

// .stateform_block{
// 	padding:1.3rem;
// 	border-radius : 0.5rem 0 0 0.5rem;
// 	background-color: #eee;
// 	margin-bottom: 1rem;
// 	margin-right: 0;
// }

// .state-delete-button {
// 	border-radius : 0 0.5rem 0.5rem 0;
// 	border: 0px;
// 	margin-bottom: 1rem;
// 	font-weight: 700;
// 	font-size:1.4rem;
// 	@include button-variant(lighten($danger,10), $white, lighten($danger,10));
// 	&:hover{
// 		background-color:$green;
// 	}
// 	//display: inline;
// }

.formset_div {
	padding-right: 0.9rem;
}

.form-group small a{
	text-decoration: none;
	color: inherit;
	font-weight: 600;
	&:hover {
		color: $primary;
	}
}

span.invalid-feedback {
  display: block;
}

textarea#id_seq, .spectrum-field textarea{
	font-size:0.8rem;
}

i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.selectaddwidget {
	// -webkit-appearance: none;
	// -moz-appearance: none;
	// background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='18' viewBox='0 0 24 24'><path fill='grey' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") #fff;
	// background-position: 98% 50%;
	// background-repeat: no-repeat;
}

// fix for corner radius on collection form
.input-group-append .btn{
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}


.query-row {
	padding: 0.2rem 0;
	.form-group{
		margin-top: 0.3rem;;
		margin-bottom: 0.2rem;
	}
}

.button-row {
	margin-top:0.5rem;
}
@include media-breakpoint-down(xs) {
	.query-row:nth-child(even) {
		background: $gray-300;
		border-radius: 3px;
	}
}


.formset_div{
	background: lighten($gray-200,1);
	//@include gradient-y($start-color: #F3F3F3, $end-color: rgba($primary, 0.15), $start-percent: 0%, $end-percent: 100%)
	border-radius: 0.4rem;
	padding: 1rem 1.5rem 0.5rem 0.5rem;
	margin: 1rem 0;
	position: relative;
	.bleach-delete-button, .state-delete-button{
		position: absolute;
		top: 0.2rem;
		right: 1.35rem;
		font-weight: 700;
		font-size:25px;
		background: none;
		border: none;
		color: $gray-600;
		box-shadow: none;
		&:hover{
			color: $gray-900;
			text-decoration: none;
		}
		&:active, &:focus{
			color: darken($red, 20);
		}
	}
	.bleach-delete-button{
		right: 0.8rem;
	}
}



.formset_div:has(> .bleach-delete-button){
	background-color: red;
}

// adds a little arrow to the right of something...
// but not using because Bootstrap accordion gives same
// id to multiple elements
// $arrow-size: 5px;
// .panel-title{
// 	a{
// 		font-size: 1rem;
// 		font-weight: 500;
// 		color: $gray-600;
// 		&::after{
// 			    content: '';
// 			    position: relative;
// 			    left: 0.6rem;
// 			    top: -0.7rem;
// 			    width: 0;
// 			    height: 0;
// 			    border-left: $arrow-size solid transparent;
// 			    border-right: $arrow-size solid transparent;
// 			    border-bottom: $arrow-size solid $gray-600;
// 			    border-top: none;
// 			    clear: both;
// 		}
// 		&:not([aria-expanded="true"]) {
// 			&::after{
// 				    content: '';
// 				    position: relative;
// 				    left: 0.6rem;
// 				    top: 0.7rem;
// 				    width: 0;
// 				    height: 0;
// 				    border-left: $arrow-size solid transparent;
// 				    border-right: $arrow-size solid transparent;
// 				    border-top: $arrow-size solid $gray-600;
// 				    border-bottom: none;
// 				    clear: both;
// 			}
// 		}
// 	}
// }


#microscopeFormTabs {
  font-size: 0.80rem;
  @include media-breakpoint-up(md) {
      font-size: 1rem;
  }
}

/* dal bootstrap css fix */
#microscopeform .select2-container {
    width: 100% !important;
}

// select2 widgets
.select2-container {
  min-width: 1em !important;
  max-width: 100%;
  width: 100%;
}

ul li.select2-selection__choice,
ul li.select2-search {
    /* Cancel out django's style */
    list-style-type: none;
}

.form-group .select2-container .select2-selection--single {
  height: 38px !important;
}
.custom-select-sm .select2-selection--single {
  //height: 28px;
  //height: calc(2.25rem + 2px) !important;
  width: 80%;
}

.select2-selection--multiple .select2-search--inline {
  width: 10px !important;
}

.select2-container--bootstrap .select2-selection--single {
  padding-left: 0 !important;
}


.select2-container--bootstrap .select2-selection--multiple  {
  line-height: 1rem;
  .select2-selection__choice {
    margin: 0.5rem 0 0 0.5rem !important;
  }
}

.bleach-form {
  .select2-selection__arrow {
    display: none;
  }

  .dynamic-form{
    .form-group span.select2-container:nth-of-type(2){
      display:none;
    }
  }
}

.microscope-form {
    .oc-delete-button{
      font-size: 2rem;
      color: #999;
      position: relative;
      top: -20px;
      right: 3px;
      width: 1px;
    }
    .oc-delete-button:hover{
      text-decoration: none;
    }
  /*  .dynamic-oc-forms .dynamic-form:last-of-type .oc-delete-button{
      display:none;
    }*/

    .dynamic-oc-forms{
      .oc-filter-group span.select2-container:nth-of-type(2){
        display:none;
      }
      .col-form-label {
        padding-top: 0;
      }
    }


    .select2-selection__choice{
      font-size: small;
    }

    .oc-filter-group .select2-selection--multiple .select2-selection__clear{
      display: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
}
