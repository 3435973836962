.report-body .nvtooltip{
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.report-tooltip{
  background: rgba(0,0,0,0.78);
  color: #eee;
  padding: 8px;
  border-radius: 4px;
  line-height: 0.7rem;
  font-size: 0.9rem;
}

#report_chart {
    margin: 0px;
    padding: 0px;
    height: 750px;
    width: 100%;
}

#status {
  margin: auto;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  color:#666;
}

.nv-point-paths path:hover {
  cursor: pointer;
}

#request-report {
  width: 80px;
}
#update-progress {
  margin: 5px;
  margin-left: 20px;
  height: 8px;
  width: calc(100% - 110px);
}

#report_chart{
  .nv-axis{
    path {
      stroke: none;
    }
    text {
      font-weight: 100;
    }
    .tick {
      stroke: none;
    }
  }
  .nv-group{
    .nv-point{
      stroke-width: 0.5px;
    }
  }
}

#report_table_info, #report_table_paginate {
  font-size: small;
  color: #888 !important;
}

.json-button {
  @include button-variant($primary, transparent, darken($primary, 5));
}
