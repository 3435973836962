$logo-color: #efe;

.navbar-scroll-custom {
  transform: translateY(-$navbar-height - .5);
  transition-duration: 0.6s;
}

@include media-breakpoint-up(sm){
  ul.nav li.dropdown:hover{
    &> .dropdown-menu {
      @include media-breakpoint-up(md){
        //display: block;
      }
    }
  }
}

#user_avatar{
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
}


.navbar {
  @include media-breakpoint-up(md) { height: $navbar-height; }

  .dropdown-menu {
      z-index: 1001;
  }
  transition-duration: 0.3s;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  @include media-breakpoint-up(md) { padding: 0 }
  @include box-shadow(0 0.1rem 0.2rem rgba(black,0.2));
  .navbar-brand{
    padding:0;
    margin-top: -4px;
    @include media-breakpoint-down(sm) {
      //font-size: 2.3rem;
      line-height: 1.6rem;
      padding-left: 14px;
      float: right;
    }
    span{
      font-size: 2.2rem;
      line-height: 2.6rem;
      font-weight: 100;
      font-family: Raleway, sans-serif !important;
      color: $logo-color;
      @include media-breakpoint-down(sm) {
        //font-size: 2.3rem;
        line-height: 1.6rem;
      }
      &.brand-FP{
        font-weight: 600;
        margin-left: -0.65rem;
        @include media-breakpoint-down(sm) { font-size: 2rem }
      }
      &.brand-version{
        font-family: $font-family-sans-serif !important;
        font-style: italic;
        font-weight: 600;
        text-shadow: 0 0 0.5rem rgba(white, 0.4);
        font-size: 0.8rem;
        position: relative;
        top: 0.2rem;
        margin-left: 0.2rem;
        color: rgba(white,0.8);
        @include media-breakpoint-down(sm) { font-size: 0.5rem }
      }
    }
    .logo-svg{
      vertical-align: inherit;
      fill: $logo-color;
      fill-rule: evenodd;
      height: 2.5rem;
      padding-top: .9rem;
      @include media-breakpoint-down(sm) { height: 2.2rem; }
    }
  }
  .nav-search{
    .input-group {
      width: 170px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-top: 8px;
      }
      transition: width 0.2s;
      &:focus-within  {
        @include media-breakpoint-up(md) {
          width: 270px;
        }
        @include media-breakpoint-up(lg) {
          width: 450px;
        }
        @include media-breakpoint-up(xl) {
          width: 540px;
        }
      }
    }
    .input-group-append {
      margin-left: 0px;
    }
    input{
      background: rgba(white, 0.25);
      border: none;
      color: white;
      width: 100%;
      font-weight: 600;
      &::placeholder {
        font-weight: 200;
        color: rgba($white,0.7);
        @include media-breakpoint-down(xs) {
          color: rgba($white,0.8);
        }
      }
      &:focus{
        background: white !important;
        color: $gray-800;
      }
      //@include media-breakpoint-up(lg) { margin-left: 1rem; }
      //@include media-breakpoint-down(md) { width: 170px; }
    }
    button{
      background: rgba(white, 0.15);
      color: #fff;
      border: none;
      &:hover {
        background: rgba(black, 0.15);
      }
    }

  }
}

.input-group-btn:not(:first-child)>.btn-group:first-child, .input-group-btn:not(:first-child)>.btn:first-child {
  margin-left:0;
}

.home-body .navbar{
  @include box-shadow(0 0 0 rgba(black,0));
}

h6.dropdown-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding-left: 1rem;
  &:after {
    background-color: #ddd;
    content: '\a0';
    flex-grow: 1;
    height: 1px;
    position: relative;
    top: 0.5em;
  }

  &:after {
    margin-left:10px;
  }
}
