
$comparison-width: 275px;

#comparison-slider{
  //@include media-breakpoint-down(sm) { display: none;}
  position: fixed;
  background: rgba(0, 0, 0, 0.85);
  color: #EEE;
  width: $comparison-width;
  right: -$comparison-width;
  height: 100%;
  z-index: 100;
  transition: right 0.3s ease-in-out;

  &.hover-effect{
    right: 0px;
  }

  #comparison-toggle{
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    right: $comparison-width - 26px;
    top: 127.5px;
    padding: 6px 13px;
    transform: rotate(-90deg);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-size: 0.9rem;
  }

  #comparison-content {
    position: absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    top: 0;
    bottom: 0;
    width: 290px;

     .add-protein{
        text-align: center;
        border-top: 1px solid black;
        padding: 15px;
        button {
          color: white;
        }
     }

     h3{
      line-height: 56px;
      text-align: center;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 0;
      color: #eee;
      background: rgba(0, 0, 0, 0.4);
     }
     ul{
       list-style:none;
       margin: 0;
       padding-left: 0;
       li {
        position: relative;
        border-top: 1px solid gray;
        padding: 13px 20px 13px 22px;
        &:last-child{
          border-bottom: 1px solid gray;
        }

        a {
          display: inline-block;
          font-size: 1.1rem;
          margin-left: 35px;
          color: white;
          opacity: .85;
          font-weight: 500;
          &:hover {
            opacity: 1;
            text-decoration: none;
          }
        }

        p {
          margin-left: 35px;
          margin-bottom: 0px;
          font-size: 0.8rem;
        }
        button.remove-protein{
          font-size: 1.8rem;
          position: absolute;
          top: 0.1rem;
          background:transparent;
          color: white;
          cursor: pointer;
          border: none;
          opacity: 0.8;
          padding: 0;
          &:hover {
            color: lighten($danger, 20);
            opacity: 1;
            font-weight: 600;
          }
        }
        img.spectrum-svg{
          position: absolute;
          bottom: -1px;
          left: 0;
          opacity: 0.4;
          width: 100%;
          z-index: -1;
        }
       }
     }
  }

  #compare-link{
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    text-align: center;
    position: relative;
    a {
      color: white;
      opacity: 0.7;
      transition: 0.2s;
      &:hover{
        opacity: 1;
        text-decoration: none;
        // &:after{
        //     content: "";
        //     position: absolute;
        //     width: 40%;
        //     height: 2px;
        //     top: 2.8rem;
        //     left: 28%;
        //     border-bottom: 2px solid lighten($secondary, 25);;
        // }
      }
    }
    .msg {
      font-size: 0.9rem;
    }
    button {
      color: white;
      opacity: 0.7;
      background: transparent;
      border: none;
      box-shadow: none !important;
      font-size: 0.85rem;
      &:hover{
        opacity: 1;
      }
    }
  }


}
