
/* Extra markup and styles for table-esque vertical and horizontal centering */
.cover-wrapper {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
  box-shadow: inset 1rem 1rem 8rem rgba($black,0.15);
  @include media-breakpoint-down(sm) {
    background-position: 50% calc(50% - 7vh);
  }
}
.cover-wrapper-inner {
  display: table-cell;
  vertical-align: middle;
  @include gradient-directional(rgba(darken($primary,5%), 1), rgba(lighten($primary, 15%),1), 160deg);
  @include media-breakpoint-down(sm) {
    @include gradient-directional(rgba(darken($primary,5%), 0.6), rgba(lighten($primary, 15%),0.6), 160deg);
  }
}

/* Padding for spacing */
.cover-container {
  padding: 1.5rem;
  @include media-breakpoint-down(sm) {
    position: relative;
    top: -7vh;
  }
}

#protein-image{
  display: block;
  height:420px;
  @include media-breakpoint-down(sm) {
    display:none;
  }
  @include media-breakpoint-down(md) {
    height:340px;
  }
}

$search-colors: rgba($white,0.4);

.search-col{
  .cover-heading {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: white;
    //font-family: Raleway, sans-serif;
    font-weight:100;
    font-size: 2.5rem;
    margin-left:-0.8rem;
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      margin-top: 2rem;
    }
  }
  .home-search{
    background-color: rgba($white,0.25);
    border-radius: 10rem;
    // the search form itself
    input{
      border-radius: 10rem;
      box-shadow: none;
      background-color:transparent;
      border: none;
      color:white;
      font-size:1.1rem;
      padding-left:1.2rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
//      font-weight: 500;
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
      &::placeholder {
        color: rgba($white,0.5);
//        font-weight: 300;
        @include media-breakpoint-down(xs) {
          color: rgba($white,0.8);
        }
      }
    }

    // the little looking-glass search button
    button{
      box-shadow: none;
      background: rgba($white, 0.0);
      border-radius: 10rem;
      border: none;
      color: white;
      //margin-right:.1rem;
      &.fa-search:before {
        color: rgba($white,0.75);
      }
      &:hover{
        background: rgba($white, 0.2);
      }
    }
  }
  .advanced-search {
    text-align: right;
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
    a {
      color: rgba($white, 0.65);
      font-size: 0.9rem;
      padding-right: 0.7rem;
      line-height: 2rem;
      @include media-breakpoint-down(xs) {
        color: rgba($white,0.8);
        font-size: 1rem;
        text-align: center;
      }
      &:hover{
        color: rgba(white,0.9);
        text-decoration: none;
        text-shadow: 0 0rem 1rem rgba(black, 0.1);
      }
    }
  }
}

.home-body{
  padding-top: 0;
  height: 100vh;
  @include gradient-directional(rgba(darken($primary,5%), 1), rgba(lighten($primary, 15%),1), 160deg);
}

.home-stats{
  position: fixed;
  bottom: 5px;
  padding-right: 20px;
  text-align: right;
  color: white;
  width: 100%;
  margin-right: 1rem;
  font-size: 1rem;
  @include media-breakpoint-up(md) {
    font-size: 1.2rem;
    //text-align: center;
  }
  a{
    opacity: 0.7;
  }
  a:hover{
    text-decoration: none;
    opacity: 1;
  }
  span {
    margin-left: 20px;
    @include media-breakpoint-up(sm) {margin-left: 40px;}
  }

  .gfpicon {
    height: 38px;
    margin-bottom:0;
    padding-bottom: 3px;
    filter: brightness(140%);
    opacity: 0.8;
  }
}
