.fav-count {
    font-size: .8em;
    padding: 2px 5px 2px 4px;
    color: #555;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    white-space: nowrap;
    border-radius: 3px;
    position: relative;
    margin-left: 4px;
    background-color: #fafafa;
    border: 1px solid #d4d4d4;
}
